import { SIZE_IDS } from './sizes';
import { SHADOW_IDS } from './shadows';

const SIZE_PRICES = {
  [SIZE_IDS['70x150']]: 700,
  [SIZE_IDS['70x180']]: 750,
  [SIZE_IDS['100x150']]: 800,
  [SIZE_IDS['100x180']]: 850,
};

const SHADOW_PRICES = {
  [SHADOW_IDS.SCIAGACZKA]: 50,
  [SHADOW_IDS.SZCZOTKA]: 60,
  [SHADOW_IDS.SZCZOTKA2]: 70,
  [SHADOW_IDS.SZUFELKA]: 80,
  [SHADOW_IDS.SZUFLA]: 90,
  [SHADOW_IDS.WIADRO]: 100,
  [SHADOW_IDS.ZMIOTKA]: 110,
};

const EMPTY_SHADOW_PRICE = 15;

export {
  SIZE_PRICES,
  SHADOW_PRICES,
  EMPTY_SHADOW_PRICE,
};
