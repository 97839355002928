import React from 'react';
import logo from './logo.svg';
import './Navigation.scss';
// import Button from '../Button';

const Navigation = () => (
  <div className="header-wrapper">
    <div className="header c-container__default">
      <div className="header-media-wrapper">
        <a href="https://www.tablice-magnetyczne.com.pl/">
          <img
            src={logo}
            className="header-media"
            alt="tablice magnetyczne, Agenda, logo"
          />
        </a>
      </div>
      <div className="header-title">
        <h3>konfigurator</h3>
      </div>
      {/* <div className="back-button"> */}
      {/* <Button */}
      {/*  className="back-button secondary" */}
      {/*  onClick={() => {}} */}
      {/*  text="Powrót do oferty" */}
      {/* /> */}
      {/* </div> */}
    </div>
  </div>
);

Navigation.propTypes = {};

export default Navigation;
