// import Tablica from './assets/cienie/Tablica.png';
import Sciagaczka from '../assets/cienie/Sciagaczka.png';
import Szczotka from '../assets/cienie/Szczotka.png';
import Szczotka2 from '../assets/cienie/Szczotka_2.png';
// import Szufelka from '../assets/cienie/Szufelka.png';
import Szufelka from '../assets/cienie/Szufelka.svg';
import Szufla from '../assets/cienie/Szufla.png';
import Wiadro from '../assets/cienie/Wiadro.png';
import Zmiotka from '../assets/cienie/Zmiotka.png';

const SHADOW_IDS = {
  SCIAGACZKA: 'sciagaczka',
  SZCZOTKA: 'szczotka',
  SZCZOTKA2: 'szczotka2',
  SZUFELKA: 'szufelka',
  SZUFLA: 'szufla',
  WIADRO: 'wiadro',
  ZMIOTKA: 'zmiotka',
};

const SHADOW_NAMES = {
  [SHADOW_IDS.SCIAGACZKA]: 'Ściągaczka',
  [SHADOW_IDS.SZCZOTKA]: 'Szczotka',
  [SHADOW_IDS.SZCZOTKA2]: 'Szczotka',
  [SHADOW_IDS.SZUFELKA]: 'Szufelka',
  [SHADOW_IDS.SZUFLA]: 'Szufla',
  [SHADOW_IDS.WIADRO]: 'Wiadro',
  [SHADOW_IDS.ZMIOTKA]: 'Zmiotka',
};

const SHADOW_ASSETS = {
  [SHADOW_IDS.SCIAGACZKA]: Sciagaczka,
  [SHADOW_IDS.SZCZOTKA]: Szczotka,
  [SHADOW_IDS.SZCZOTKA2]: Szczotka2,
  [SHADOW_IDS.SZUFELKA]: Szufelka,
  [SHADOW_IDS.SZUFLA]: Szufla,
  [SHADOW_IDS.WIADRO]: Wiadro,
  [SHADOW_IDS.ZMIOTKA]: Zmiotka,
};

const SHADOWS = [
  SHADOW_IDS.SCIAGACZKA,
  SHADOW_IDS.SZCZOTKA,
  SHADOW_IDS.SZCZOTKA2,
  SHADOW_IDS.SZUFELKA,
  SHADOW_IDS.SZUFLA,
  SHADOW_IDS.WIADRO,
  SHADOW_IDS.ZMIOTKA,
];

export {
  SHADOWS,
  SHADOW_IDS,
  SHADOW_ASSETS,
  SHADOW_NAMES,
};
