/* eslint-disable no-underscore-dangle */

import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import { filter } from 'lodash';
import { fabric } from 'fabric';

import StepWorkspace from './StepWorkspace';
import { SHADOW_ASSETS } from '../../../../config/shadows';
import { fabricHelper } from '../../../../helpers/utilities';
import { addDeleteEventListener, removeDeleteEventListener } from '../../../../helpers/handleDeletePress';
import { SIZE_ASSETS } from '../../../../config/sizes';
import { useUserSettings } from '../../../../hooks/useUserSettings';

const BOARD_ID = 'board-id';
const BOARD_Z_INDEX = -100;

const StepWorkspaceContainer = () => {
  const [shadows, setShadows] = useState([]);
  const { boardSize, shadowsColor } = useUserSettings();
  const canvasRef = useRef(null);
  const [canvas, setCanvas] = useState(null);

  const addNewShadow = (shadow) => {
    setShadows([
      ...shadows,
      shadow,
    ]);
  };

  const removeShadow = (id) => setShadows(
    (freshShadows) => filter(freshShadows, (shadow) => shadow.id !== id),
  );

  const reloadBoard = useCallback(async (currentCanvas) => {
    const boardImage = await fabricHelper.createNewImage(SIZE_ASSETS[boardSize]);
    const boardFabricElement = fabricHelper.createNewFabricImage(boardImage, {
      ...fabricHelper.defaultBoardConfig,
      id: BOARD_ID,
    });

    fabricHelper.deselectAllObjects(currentCanvas);
    fabricHelper.removeElementById(currentCanvas, BOARD_ID);
    fabricHelper.addElementToCanvas(currentCanvas, boardFabricElement);
    fabricHelper.moveElementToZindex(currentCanvas, boardFabricElement, BOARD_Z_INDEX);
    fabricHelper.setControlsVisibility(
      boardFabricElement, fabricHelper.defaultBoardControlsVisibility,
    );
  }, [boardSize]);

  const loadImageShape = async (shadowId, id) => {
    const shadowImage = await fabricHelper.createNewImage(SHADOW_ASSETS[shadowId]);
    const shadowFabricElement = fabricHelper
      .createNewFabricImage(shadowImage, { ...fabricHelper.defaultShadowConfig, id });

    fabricHelper.addElementToCanvas(canvas, shadowFabricElement);
    fabricHelper.setControlsVisibility(
      shadowFabricElement, fabricHelper.defaultShadowControlsVisibility,
    );
  };

  const loadSvgShape = (color) => async (shadowId, id) => {
    const shadowFabricElement = await fabricHelper
      .createNewFabricSvg(SHADOW_ASSETS[shadowId], color, id);

    fabricHelper.addElementToCanvas(canvas, shadowFabricElement);
    fabricHelper.setControlsVisibility(
      shadowFabricElement, fabricHelper.defaultShadowControlsVisibility,
    );
  };

  const loadShape = async (shadowId, id) => {
    const asset = SHADOW_ASSETS[shadowId];
    addNewShadow({ shadowId, id });

    if (asset.includes('.svg')) {
      loadSvgShape(shadowsColor)(shadowId, id);
    } else {
      await loadImageShape(shadowId, id);
    }
  };

  useEffect(() => {
    const canvasConstructed = new fabric.Canvas(canvasRef.current);
    setCanvas(canvasConstructed);
    addDeleteEventListener(canvasConstructed, removeShadow);

    return () => {
      removeDeleteEventListener();
    };
  }, []);

  useEffect(() => {
    if (canvas) {
      reloadBoard(canvas);
    }
  }, [canvas, reloadBoard]);

  useEffect(() => {
    if (canvas) {
      fabricHelper.recolorElements(canvas, shadowsColor);
    }
  }, [canvas, shadowsColor]);

  return (
    <StepWorkspace
      canvasRef={canvasRef}
      loadShape={loadShape}
      shadows={shadows}
      serializeCanvas={() => fabricHelper.serializeCanvas(canvas)}
    />
  );
};

StepWorkspaceContainer.propTypes = {};

export default StepWorkspaceContainer;
