import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const UserSettingsContext = createContext({});

export const UserSettingsProvider = ({ userSettings, children }) => (
  <UserSettingsContext.Provider value={userSettings}>
    {children}
  </UserSettingsContext.Provider>
);

export const useUserSettings = () => useContext(UserSettingsContext);

UserSettingsProvider.propTypes = {
  userSettings: PropTypes.shape({}),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};
