import React from 'react';
import PropTypes from 'prop-types';
import StepHeader from '../../components/StepHeader';

import './StepShadowsColor.scss';

const StepShadowsColor = ({ shadowsColor, setShadowsColor }) => (
  <>
    <StepHeader stepNumber={1} title="Wybierz kolor cieni" />
    <div className="row-wrapper">
      <input
        className="color-picker"
        type="color"
        value={shadowsColor}
        onChange={(event) => setShadowsColor(event.target.value)}
      />
    </div>
  </>
);

StepShadowsColor.propTypes = {
  shadowsColor: PropTypes.string,
  setShadowsColor: PropTypes.func.isRequired,
};

export default StepShadowsColor;
