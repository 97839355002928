import Tablica100x180 from '../assets/Tablica.png';

const SIZE_IDS = {
  '70x150': '70x150',
  '70x180': '70x180',
  '100x150': '100x150',
  '100x180': '100x170',
};

const SIZE_NAMES = {
  [SIZE_IDS['70x150']]: '70 x 150cm',
  [SIZE_IDS['70x180']]: '70 x 180cm',
  [SIZE_IDS['100x150']]: '100 x 150cm',
  [SIZE_IDS['100x180']]: '100 x 180cm',
};

const SIZE_ASSETS = {
  [SIZE_IDS['70x150']]: Tablica100x180,
  [SIZE_IDS['70x180']]: Tablica100x180,
  [SIZE_IDS['100x150']]: Tablica100x180,
  [SIZE_IDS['100x180']]: Tablica100x180,
};

const SIZE_ARRAY = [
  SIZE_IDS['70x150'],
  SIZE_IDS['70x180'],
  SIZE_IDS['100x150'],
  SIZE_IDS['100x180'],
];

export {
  SIZE_IDS,
  SIZE_ASSETS,
  SIZE_NAMES,
  SIZE_ARRAY,
};
