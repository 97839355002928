import React from 'react';
import PropTypes from 'prop-types';

import './StepHeader.scss';

const StepHeader = ({ stepNumber, title, description }) => (
  <div className="step-header">
    <div className="circle">
      <span>{ stepNumber }</span>
    </div>
    <div>
      <h3>{title}</h3>
      {description && <h5 className="description">{description}</h5>}
    </div>
  </div>
);

StepHeader.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default StepHeader;
