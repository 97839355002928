import React, { useState } from 'react';
import Navigation from './components/Navigation';
import './App.scss';
import Stepper from './components/Stepper';
import MobileOverlay from './components/MobileOverlay';
import { UserSettingsProvider } from './hooks/useUserSettings';

const App = () => {
  const [boardSize, setBoardSize] = useState(null);
  const [shadowsColor, setShadowsColor] = useState('#3e9dd1');
  const userSettings = {
    boardSize,
    setBoardSize,
    shadowsColor,
    setShadowsColor,
    allSet: !!boardSize,
  };

  return (
    <UserSettingsProvider userSettings={userSettings}>
      <div className="App">
        <Navigation />
        <Stepper />
      </div>
      <MobileOverlay />
    </UserSettingsProvider>
  );
};

export default App;
