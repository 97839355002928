/* eslint-disable no-underscore-dangle */

import { fabric } from 'fabric';
import { find, debounce } from 'lodash';
import { dataURLtoBlob } from './dataURLtoBlob';

const defaultControlsVisbility = {
  mt: false,
  mb: false,
  ml: false,
  mr: false,
  bl: false,
  br: false,
  tl: false,
  tr: false,
};

const defaultConfig = {
  left: 90,
  top: 20,
};

const defaultBoardConfig = {
  ...defaultConfig,
  zindex: -100,
  selectable: false,
  lockMovementX: true,
  lockMovementY: true,
  scaleX: 0.08,
  scaleY: 0.08,
};

const defaultShadowConfig = {
  ...defaultConfig,
  snapAngle: 180,
};

const defaultShadowControlsVisibility = {
  ...defaultControlsVisbility,
  mtr: true,
};

const defaultBoardControlsVisibility = {
  ...defaultControlsVisbility,
  mtr: false,
};

const createNewImage = (asset) => new Promise((resolve, reject) => {
  const boardImg = new Image();
  boardImg.onload = (img) => resolve(img);
  boardImg.onerror = reject;
  boardImg.src = asset;
});

const createNewFabricSvg = (asset, color, id) => new Promise((resolve) => {
  fabric.loadSVGFromURL(asset, (objects, options) => {
    const fabricElement = fabric.util.groupSVGElements(objects, options);
    fabricElement.set({
      left: 100,
      top: 100,
      id,
      snapAngle: 180,
    });

    // eslint-disable-next-line no-underscore-dangle,no-param-reassign,no-return-assign
    fabricElement._objects.forEach((obj) => obj.fill = color);
    resolve(fabricElement);
  });
});

const moveElementToZindex = (canvas, element, zindex) => canvas.moveTo(element, zindex);

const addElementToCanvas = (canvas, element) => {
  canvas.add(element);
  canvas.renderAll();
};

const removeElementFromCanvas = (canvas, element) => canvas.remove(element);

const setControlsVisibility = (fabricElement, controlsVisibility) => fabricElement
  .setControlsVisibility(controlsVisibility);

const createNewFabricImage = (img, config) => new fabric.Image(img.target, config);

const findAndActivateFabricElementById = (canvas, elementId) => {
  const fabricElement = find(canvas.getObjects, (fabricObject) => fabricObject.id === elementId);
  if (fabricElement) {
    canvas.setActiveObject(fabricElement);
  }
};

const removeElementById = (canvas, elementId) => {
  findAndActivateFabricElementById(canvas, elementId);
  removeElementFromCanvas(canvas, canvas.getActiveObject());
};

const recolorElements = (canvas, color) => {
  canvas.getObjects()
    .forEach((fabricElement) => {
      if (fabricElement._objects) {
        // eslint-disable-next-line no-return-assign,no-param-reassign
        fabricElement._objects.forEach((svgObject) => svgObject.fill = color);
      }
      fabricElement.set('dirty', true);
    });
  canvas.requestRenderAll();
};

const downloadCanvas = (canvas) => {
  const link = document.createElement('a');
  const imgData = canvas.toDataURL({
    format: 'png',
    multiplier: 4,
  });

  imgData.substr(22, imgData.length);
  const blob = dataURLtoBlob(imgData);
  const objurl = URL.createObjectURL(blob);

  link.download = 'board.png';

  link.href = objurl;

  link.click();
};
const serializeCanvas = (canvas) => {
  downloadCanvas(canvas);
};

const deselectAllObjects = (canvas) => canvas.discardActiveObject().renderAll();
const fabricHelper = {
  createNewImage,
  moveElementToZindex,
  addElementToCanvas,
  removeElementFromCanvas,
  setControlsVisibility,
  createNewFabricImage,
  removeElementById,
  deselectAllObjects,
  createNewFabricSvg,
  recolorElements: debounce((canvas, color) => recolorElements(canvas, color), 100),
  defaultBoardControlsVisibility,
  defaultShadowControlsVisibility,
  defaultBoardConfig,
  defaultShadowConfig,
  serializeCanvas,
};

export {
  fabricHelper,
};
