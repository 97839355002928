import React from 'react';

import './MobileOverlay.scss';

const MobileOverlay = () => (
  <div className="mobile-overlay">
    <div>
      <h4>Konfigurator może nie działać poprawnie na urządzeniach mobilnych</h4>
      <br />
      <h3>
        Zapraszamy do skorzystania
        <br />
        na urządzeniach z większym ekranem
      </h3>
    </div>
  </div>
);

MobileOverlay.propTypes = {};

export default MobileOverlay;
