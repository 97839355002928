import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import './StepSummary.scss';
import { useUserSettings } from '../../../../hooks/useUserSettings';
import StepHeader from '../../components/StepHeader';
import { SIZE_NAMES } from '../../../../config/sizes';

const StepSummary = ({ summary = {} }) => {
  const {
    price = 0, priceWithAccessories = 0, hooksNumber, accessories,
  } = summary;
  const { boardSize } = useUserSettings();

  return (
    <div className="summary">
      <StepHeader stepNumber={3} title="Prześlij zapytanie" description="Uzupełnij email lub numer telefonu, a my skontaktujemy się w celu ustalenia szczegółów i potwierdzenia projektu tablicy" />
      <div className="summary-row">
        <div>
          <p>
            <span className="light">Wybrany rozmiar tablicy:</span>
            <span className="price block">{SIZE_NAMES[boardSize]}</span>
          </p>
          <p>
            <span className="light">Ilość uchwytów na akcesoria:</span>
            <span className="price block">{hooksNumber}</span>
          </p>
          {accessories && (
            <p>
              <span className="light">Wybrane akcesoria:</span>
              <span className="price block">{accessories}</span>
            </p>
          )}
        </div>
        <div>
          <p className="right-align">
            <span className="light">Cena bez akcesoriów*</span>
            <span className="price bold block">{` ${price} zł`}</span>
          </p>
          <p className="right-align">
            <span className="light">Cena z akcesoriami*</span>
            <span className="price bold block">{` ${priceWithAccessories} zł`}</span>
          </p>
        </div>
      </div>
      <div className="summary-row">
        <p />
        <Button className="right-align" variant="contained" color="primary">
          Prześlij zapytanie
        </Button>
      </div>
    </div>
  );
};

StepSummary.propTypes = {
  summary: PropTypes.shape({
    price: PropTypes.number.isRequired,
    priceWithAccessories: PropTypes.number.isRequired,
  }),
};

export default StepSummary;
