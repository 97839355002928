import React from 'react';
import PropTypes from 'prop-types';
import StepHeader from '../../components/StepHeader';
import { SIZE_ARRAY, SIZE_NAMES, SIZE_ASSETS } from '../../../../config/sizes';
import ChoiceTile from '../../../ChoiceTile';

import './StepBoardSize.scss';

const StepBoardSize = ({ boardSize, setBoardSize }) => (
  <>
    <StepHeader stepNumber={2} title="Wybierz rozmiar tablicy" />
    <div className="choice-tiles">
      {SIZE_ARRAY.map((sizeId) => (
        <ChoiceTile
          key={sizeId}
          onClick={() => setBoardSize(sizeId)}
          title={SIZE_NAMES[sizeId]}
          active={boardSize === sizeId}
          asset={SIZE_ASSETS[sizeId]}
        />
      ))}
    </div>
  </>
);

StepBoardSize.propTypes = {
  boardSize: PropTypes.string,
  setBoardSize: PropTypes.func.isRequired,
};

export default StepBoardSize;
