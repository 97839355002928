import React from 'react';
import PropTypes from 'prop-types';

import './Board.scss';

const Board = ({ canvasRef }) => (
  <div className="board">
    <canvas ref={canvasRef} width="500px" height="600px" />
  </div>
);

Board.propTypes = {
  canvasRef: PropTypes.shape().isRequired,
};

export default Board;
