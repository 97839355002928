import React from 'react';
import PropTypes from 'prop-types';
import StepSummary from './StepSummary';
import { useUserSettings } from '../../../../hooks/useUserSettings';
import { SHADOW_PRICES, SIZE_PRICES, EMPTY_SHADOW_PRICE } from '../../../../config/prices';
import { SHADOW_NAMES } from '../../../../config/shadows';

const calculatePrice = ({ boardSize, shadows }) => {
  const boardPrice = SIZE_PRICES[boardSize];
  const shadowsPrice = shadows.reduce((acc) => acc + EMPTY_SHADOW_PRICE, 0);

  return boardPrice + shadowsPrice;
};

const calculatePriceWithAccessories = ({ boardSize, shadows }) => {
  const boardPrice = SIZE_PRICES[boardSize];
  const shadowsPrice = shadows.reduce((acc, shadow) => acc + SHADOW_PRICES[shadow.shadowId], 0);

  return boardPrice + shadowsPrice;
};

const SummaryContainer = ({ shadows }) => {
  const { boardSize } = useUserSettings();

  const summary = {
    price: calculatePrice({ boardSize, shadows }),
    priceWithAccessories: calculatePriceWithAccessories({ boardSize, shadows }),
    hooksNumber: shadows.length,
    accessories: shadows.map((shadow) => SHADOW_NAMES[shadow.shadowId]).join(', '),
  };

  return (
    <StepSummary summary={summary} />
  );
};

SummaryContainer.propTypes = {
  shadows: PropTypes.arrayOf(PropTypes.shape({
    shadowId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })),
};

export default SummaryContainer;
