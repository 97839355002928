import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { SHADOW_ASSETS, SHADOW_NAMES, SHADOWS } from '../../../../../../config/shadows';

import './ShadowsContainer.scss';
import { SHADOW_PRICES } from '../../../../../../config/prices';

const ShadowsContainer = ({ loadShape }) => (
  <div className="shadows-container">
    <div className="shadows-wrapper">
      {SHADOWS.map((shadowId) => (
        <div
          key={shadowId}
          role="button"
          className="shadow-wrapper"
          onClick={() => loadShape(shadowId, `${SHADOW_NAMES[shadowId]}-${uniqueId()}`)}
        >
          <img src={SHADOW_ASSETS[shadowId]} alt={SHADOW_NAMES[shadowId]} />
          <h4>
            <span className="bold">{SHADOW_NAMES[shadowId]}</span>
            <br />
            {`(${SHADOW_PRICES[shadowId]} zł)`}
          </h4>
        </div>
      ))}
    </div>
  </div>
);

ShadowsContainer.propTypes = {
  loadShape: PropTypes.func.isRequired,
};

export default ShadowsContainer;
