import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './ChoiceTile.scss';

const ChoiceTile = ({
  active, onClick, asset, title,
}) => (
  <div
    role="button"
    onClick={onClick}
    className={classnames({
      'choice-tile': true,
      active,
    })}
  >
    <div className="image">
      <img src={asset} alt={title} />
    </div>
    <div className="title">
      {title}
    </div>
  </div>
);

ChoiceTile.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  asset: PropTypes.node,
  title: PropTypes.string.isRequired,
};

export default ChoiceTile;
