import React from 'react';
import PropTypes from 'prop-types';

import './StepWorkspace.scss';
import Button from '@material-ui/core/Button';
import ShadowsContainer from './components/ShadowsContainer';
import Board from './components/Board';
import Summary from '../StepSummary';
import StepHeader from '../../components/StepHeader';

const StepWorkspace = ({
  loadShape,
  canvasRef,
  shadows,
  serializeCanvas,
}) => (
  <div className="workspace-wrapper c-container__default">
    <StepHeader stepNumber={3} title="Konfiguruj cienie" description="Kliknij w wybrane cienie i ułóż je na tablicy w wybranej pozycji. Cienie można obracać o 180 stopni. Aby usunąć cień, wybierz jeden z nich i wciśnij Backspace lub Delete" />
    <div className="workspace">
      <Board canvasRef={canvasRef} />
      <ShadowsContainer loadShape={loadShape} />
    </div>
    <Summary shadows={shadows} />
    <Button onClick={serializeCanvas} variant="contained" color="primary">
      Pobierz tablicę
    </Button>
    <p className="offer-info">* Przedstawiona oferta cenowa ma charakter informacyjny i nie stanowi oferty handlowej w rozumieniu Art.66 par.1 Kodeksu Cywilnego.</p>
  </div>
);

StepWorkspace.propTypes = {
  loadShape: PropTypes.func.isRequired,
  canvasRef: PropTypes.shape({}).isRequired,
  shadows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    shadowId: PropTypes.string.isRequired,
  })),
  serializeCanvas: PropTypes.func.isRequired,
};

export default StepWorkspace;
