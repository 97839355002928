const handleEvent = (event, canvas, removeShadow) => {
  if (event.defaultPrevented) {
    return;
  }

  if (event.key === 'Backspace' && !!canvas) {
    const activeObject = canvas.getActiveObject();
    canvas.remove(canvas.getActiveObject());
    removeShadow(activeObject.id);
  }

  event.preventDefault();
};

const addDeleteEventListener = (canvas, removeShadow) => {
  window.addEventListener('keydown', (event) => handleEvent(event, canvas, removeShadow), true);
};

const removeDeleteEventListener = () => {
  window.removeEventListener('keydown', handleEvent);
};

export {
  addDeleteEventListener,
  removeDeleteEventListener,
};
