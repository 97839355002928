import React from 'react';
import PropTypes from 'prop-types';

import StepBoardSize from './steps/StepBoardSize';

import './Stepper.scss';
import { useUserSettings } from '../../hooks/useUserSettings';
import Workspace from './steps/StepWorkspace';
import StepShadowsColor from './steps/StepShadowsColor';

const Stepper = () => {
  const {
    boardSize, setBoardSize, shadowsColor, setShadowsColor, allSet,
  } = useUserSettings();

  return (
    <div className="stepper c-container__default">
      <h1>Wykonaj poniższe kroki aby skonfigurować swoją tablicę cieni:</h1>
      <StepShadowsColor shadowsColor={shadowsColor} setShadowsColor={setShadowsColor} />
      <StepBoardSize boardSize={boardSize} setBoardSize={setBoardSize} />
      {allSet && <Workspace />}
    </div>
  );
};

Stepper.propTypes = {
  userSettings: PropTypes.shape({}),
};

export default Stepper;
